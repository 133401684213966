<template>
  <div>
    <div v-if="!subscribed">
      <div class="brochure">
        <div v-if="!result">
          <div class="brochure__text">
            <h2><span>Abonneer je op</span><span>onze nieuwsbrief</span></h2>
            <Usp
              :icon="'newspaper'"
              :text="'Altijd op de hoogte van acties en nieuws'"
              :text-color="'black'"
              :icon-color="'orange'" />
          </div>
          <div class="brochure__button-and-input">
            <FormTextInput
              v-model="emailAddress"
              :placeholder="contentStore.content?.emailAddress ?? ''"
              :show-validation="(emailAddress?.length ?? 0) > 0"
              @input="
                validateRegex($event, regex.email, contentStore.content?.validationEmail ?? '')
              " />
            <ButtonsBaseButton :color="'green'" @click="handleSubscribe">{{
              contentStore.content?.signUp
            }}</ButtonsBaseButton>
          </div>
        </div>
        <div v-else>
          <div class="brochure__text--result">
            <h2><span>Abonneer je op</span><span>onze nieuwsbrief</span></h2>
          </div>
          <div class="brochure__check">
            <ImagesSvg :icon="'check-usp'" :width="96" :height="96"></ImagesSvg>
          </div>
          <div class="mb-2">
            <span>Dankjewel voor je aanmelding!</span>
            <span>Je krijgt een bevestiging in je mailbox.</span>
          </div>
          <div class="progress active"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const contentStore = useContentStore();

const { subscribeToNewsletter } = useNewsletterApi();
const { validateRegex, isInputValid } = useValidation();

const { $toast } = useNuxtApp();

const emailAddress = ref<string>();

const result = ref<boolean>(false);
const duration = ref<number>(5);
const subscribed = ref<boolean>(false);

watch(result, () => {
  setTimeout(() => {
    subscribed.value = true;
  }, duration.value * 1000);
});

const handleSubscribe = async () => {
  if (!emailAddress.value || !isInputValid(emailAddress.value, regex.email)) {
    return;
  }

  try {
    const response = await subscribeToNewsletter(emailAddress.value);
    if (response.raw.ok) {
      result.value = true;
    } else {
      $toast({
        alertText: contentStore.content?.somethingWentWrong ?? '',
        type: 'error',
        duration: NOTIFICATION_DURATION.ERROR,
      });
    }
  } catch (e: any) {
    const res = await e.response.json();
    if (res.validationErrors) {
      $toast({
        alertText: res.validationErrors ?? '',
        type: 'error',
        duration: NOTIFICATION_DURATION.ERROR,
      });
    }
  }
};

const animationTime = `${duration.value}s`;
</script>

<style lang="scss" scoped>
.brochure {
  position: relative;
  width: 100%;
  min-height: px(200);
  max-height: px(300);
  height: fit-content;
  background: $c-green-1;
  border-radius: px(8);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 27%, 78% 0);
  color: $c-black;
  padding: $size-sm $size-md $size-md $size-md;
  overflow: hidden;

  span {
    display: inline-block;
    font-weight: 600;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: px(6);
    margin-bottom: $size-md;
    font-weight: 600;

    &--result {
      margin-bottom: $size-xxs;
    }
  }

  &__check {
    display: flex;
    justify-content: center;
    color: $c-green-3;
  }

  &__button-and-input {
    display: flex;
    flex-direction: column;
    gap: px(16);
    width: 100%;
  }
}

.brochure.active {
  transform: translateX(0%);
}

.brochure .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: px(8);
  width: 100%;
}

.brochure .progress::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: $c-green-3;
}

.progress.active::before {
  animation: progress #{v-bind(animationTime)} linear forwards;
}

@keyframes progress {
  100% {
    right: 100%;
  }
}
</style>
